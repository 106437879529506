import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache) {
  const _component_van_swipe_item = _resolveComponent("van-swipe-item");
  const _component_van_swipe = _resolveComponent("van-swipe");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_van_swipe, {
    class: "my-swipe",
    autoplay: 3000,
    "indicator-color": "white",
    loop: "false"
  }, {
    default: _withCtx(() => [_createVNode(_component_van_swipe_item, null, {
      default: _withCtx(() => [_createTextVNode("1")]),
      _: 1
    }), _createVNode(_component_van_swipe_item, null, {
      default: _withCtx(() => [_createTextVNode("2")]),
      _: 1
    }), _createVNode(_component_van_swipe_item, null, {
      default: _withCtx(() => [_createTextVNode("3")]),
      _: 1
    }), _createVNode(_component_van_swipe_item, null, {
      default: _withCtx(() => [_createTextVNode("4")]),
      _: 1
    })]),
    _: 1
  })]);
}